var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"hero",attrs:{"fluid":""}},[(_vm.shoppings.records.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list',{staticClass:"forlist"},[_c('v-list-item-group',_vm._l((_vm.shoppings.records),function(i){return _c('v-list-item',{key:i.id,scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('h2',[_vm._v(_vm._s(i.description))])]),(i.done === true)?_c('p',{staticClass:"green--text"},[_vm._v(" Employer bought this already ")]):_c('p',{staticClass:"red--text"},[_vm._v(" Employer still needs to buy this ")])],1),_c('p',[_vm._v(_vm._s(_vm._f("getFormattedYYYYMMDD")(i.createdAt)))])]}}],null,true)})}),1),_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-col',{staticClass:"text-right"},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"green","fab":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,false,950420621),model:{value:(_vm.AddShoppingDialog),callback:function ($$v) {_vm.AddShoppingDialog=$$v},expression:"AddShoppingDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_c('h1',[_vm._v("Add an item")])]),_c('v-card-text',[_c('shopping-edit',{attrs:{"assignedTo":_vm.me.id,"assignedToName":_vm.me.firstName + ' ' + _vm.me.lastName}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.AddShoppingDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)],1),(
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
      )?_c('v-col',{staticClass:"absolute-center",attrs:{"cols":"12","md":"6"}},[_c('v-avatar',{attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":require("../../assets/FairwageLogo.png")}})],1)],1):_vm._e()],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("No shopping list has been created yet")]),_c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-col',{staticClass:"text-right"},[_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"green","fab":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}]),model:{value:(_vm.AddShoppingDialog),callback:function ($$v) {_vm.AddShoppingDialog=$$v},expression:"AddShoppingDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_c('h1',[_vm._v("Add an item")])]),_c('v-card-text',[_c('shopping-edit',{attrs:{"assignedTo":_vm.me.id,"assignedToName":_vm.me.firstName + ' ' + _vm.me.lastName}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.AddShoppingDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1),(
        _vm.$vuetify.breakpoint.md ||
        _vm.$vuetify.breakpoint.lg ||
        _vm.$vuetify.breakpoint.xl
      )?_c('v-col',{staticClass:"absolute-center",attrs:{"cols":"12","md":"6"}},[_c('v-avatar',{attrs:{"size":"200","tile":""}},[_c('v-img',{attrs:{"src":require("../../assets/FairwageLogo.png")}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }